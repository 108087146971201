import { computed, unref } from 'vue'
import { useStore } from 'vuex'

export const useHighlight = (field = null) => {
    const store = useStore()
    const fieldUnRef = unref(field)
    const valueRef = computed(() => store.state.textract.fields[fieldUnRef])

    const setHighlightValue = (fieldParam, valueParam) => {
        store.dispatch('setFieldHighlight', {
            field: fieldParam,
            value: valueParam,
        })
    }

    return {
        value: valueRef,
        setHighlightValue,
    }
}
